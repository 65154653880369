.Teams {
  width: 1250px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 10rem 15px 3rem 15px;
}

.teams-header {
  align-self: center;
  text-align: center;
}

.teams-pinkline {
  width: 26%;
}

.founder-header {
  font-size: 2.8rem;
  font-family: "Zilla Slab", serif;
  font-weight: bold;
}

.carousel-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1440px) {
  .Teams {
    width: auto;
}
}

@media (min-width: 991px) and (max-width: 1199px) {
  .Teams {
    width: auto;
}
}

@media (min-width: 767px) and (max-width: 991px) {
  .Teams {
    width: auto;
}
}

/* Mobile devices (480px - 767px) */
@media (min-width: 479px) and (max-width: 767px) {
  html {
    font-size: 18px;
  }

  .Teams {
    width: auto;
    padding: 8rem 15px 5rem 15px;
  }

  .teams-pinkline {
    margin-bottom: 1rem;
    width: 38vw;
}

  .teams-header {
    align-self: center;
    text-align: center;
    margin-bottom: 2rem;
}

  .founder-header {
    font-size: 2.2rem;
    font-family: "Zilla Slab", serif;
    font-weight: bold;
}

  .carousel-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

}

/* Small mobile devices (up to 479px) */
@media (max-width: 479px) {
  .Teams {
    width: auto;
    padding: 10rem 15px 5rem 15px;
  }

  .teams-pinkline {
    margin-bottom: 1rem;
    width: 50%;
}

  .teams-header {
    align-self: center;
    text-align: center;
    margin-bottom: 2rem;
}

  .founder-header {
    font-size: 2.2rem;
    font-family: "Zilla Slab", serif;
    font-weight: bold;
}

  .carousel-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}
}
