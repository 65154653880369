.main-section-pahelapp {
  width: 1250px;
  margin: 0 auto;
  padding: 0 15px;
}

.Pahelapp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 8rem 0 6rem 0;
}

.pahelapp-image-container {
  width: 72%;
}

.Pahelapp > div > img {
  width: 100%;
}

.Pahelapp > div > p {
  font-size: 1.4rem;
}

.input-container {
  margin: 1rem 0;
}

.subscribe-input {
  border: 0;
  background-color: #f9f9f9;
  height: 2.2rem;
  width: 80%;
  padding: 0.2rem 1rem;
  font-size: 0.8rem;
  border-radius: 11px;
  border: 1.6px solid #e55a7a00;
  border: 1.6px solid #e35979bc;
}

.subscribe-input:focus {
  border: 1.6px solid #e55a7b;
}

.subscribe-btn {
  padding: 0.5rem 1.8rem;
  background: #b23b57 0% 0% no-repeat padding-box;
  border: 2px solid #b23b57;
  border-radius: 38px;
  text-align: center;
  color: #ffffff;
}

/*Large Desktops */
@media (max-width: 1400px) {
  .main-section-pahelapp {
    width: auto;
  }
}

/* Tablets (768px - 1023px) */
@media (min-width: 767px) and (max-width: 1023px) {
  .main-section-pahelapp {
    width: auto;
  }

  .pahelapp-image-container {
    width: 65%;
  }
  .pahelapp-text-container {
    width: 30%;
  }

  .Pahelapp > div > p {
    font-size: 2rem;
  }
  .subscribe-input {
    font-size: 1.2rem;
  }
  html {
    font-size: 12px;
  }
}

/* Mobile devices (480px - 767px) */
@media (min-width: 480px) and (max-width: 767px) {
  .main-section-pahelapp {
    width: auto;
  }

  .pahelapp-image-container {
    width: 100%;
  }

  .pahelapp-text-container {
    /* width: 60%; */
    margin-top: 1rem;
  }

  .Pahelapp > div > p {
    font-size: 1.4rem;
  }
  .subscribe-input {
    font-size: 0.8rem;
  }

  html {
    font-size: 18px;
  }
}

/* Small mobile devices (up to 479px) */
@media (max-width: 479px) {
  .main-section-pahelapp {
    width: auto;
  }

  .pahelapp-image-container {
    width: 100%;
  }

  .Pahelapp > div > img {
    width: 100%;
    /* margin-bottom: 4rem; */
  }

  .pahelapp-text-container {
    /* width: 100%; */
    margin-top: 1rem;
  }

  .Pahelapp > div > p {
    font-size: 1.6rem;
    align-self: center;
    margin: auto;
  }
  .subscribe-input {
    font-size: 0.85rem;
  }
}
