.card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 15vw;
  height: 15vw;
  border: 2px solid #e55a7b;
  border-radius: 28px;
  padding: 1rem 0.5rem;
}

.card > img {
  width: 80px;
}

.card-header {
  color: #e55a7b;
  font-size: 1.2rem;
  padding: 0.3rem 0;
  text-align: center;
}

.card-description {
  font-size: 0.7rem;
  text-align: center;
}

/* Large desktops or high-resolution displays (1440px and up) */
@media (min-width: 1440px) {
  .card {
    width: 29%;
    height: 50%;
}

}

/* Desktops (1280px - 1439px) */
@media (min-width: 1280px) and (max-width: 1439px) {

}

/* Laptops (1024px - 1279px) */
@media (min-width: 1199px) and (max-width: 1280px) {
  .card {
    width: 18vw;
    height: 18vw;
  }

  .card-header {
    font-size: 1.4rem;
  }

  .card-description {
    font-size: 0.8rem;
    text-align: center;
    padding-bottom: 2px;
  }
}

@media (min-width: 1023px) and (max-width: 1199px) {
  .card {
    width: 19vw;
    height: 19vw;
  }

  .card-header {
    font-size: 1.4rem;
  }

  .card-description {
    font-size: 0.8rem;
    text-align: center;
    padding-bottom: 2px;
  }
}

/* Tablets (768px - 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
  .card {
    width: 100%;
    height: 20vw;
    margin: 4px;
  }

  .card > img {
    width: 25%;
  }

  .card-header {
    color: #e55a7b;
    font-size: 1.6rem;
    padding: 0;
  }

  .card-description {
    font-size: 1rem;
    text-align: center;
  }
}

/* Mobile devices (480px - 767px) */
@media (min-width: 580px) and (max-width: 768px) {
  .card {
    width: 30vw;
    height: 30vw;
    margin-bottom: 2rem;
  }

  .card > img {
    width: 55px;
  }
}


/* Small mobile devices (up to 479px) */
@media (max-width: 580px) {
  .card {
    width: 65vw;
    height: 50vw;
    margin: 8px;
    padding: 20px 10px;
  }
  .card > img {
    width: 60px;
  }

  .card-header {
    color: #e55a7b;
    font-size: 1.8rem;
    padding: 15px 0;
  }

  .card-description {
    font-size: 1.1rem;
  }
}

@media (max-width: 380px) {
  .card {
    width: 70vw;
    height: 60vw;
    margin: 8px;
    padding: 20px 10px;
  }
  .card > img {
    width: 60px;
  }

  .card-header {
    color: #e55a7b;
    font-size: 1.6rem;
    padding: 15px 0;
  }

  .card-description {
    font-size: 1rem;
  }

}

@media (max-width: 380px) {
  .card {
    width: 70vw;
    height: 60vw;
    margin: 8px;
    padding: 20px 10px;
  }
  
  .card > img {
    width: 70px;
}
}
