.ImageCarousel {
  width: 50%;
  margin: 3rem 0rem 3rem 3rem;
}

.image-carousel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 85%;
  position: relative;
}

.img-container-carousel {
  display: flex;
  position: relative;
  transition: transform 0.5s ease;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carouse-bgc {
  width: 85%;
  /* transition: transform 0.5s ease; */
}

.carousel-img {
  position: absolute;
  left: 18%;
  width: 55%;
  /* transition: transform 0.5s ease; */
}

.left-btn-img {
  position: absolute;
  left: 1%;
  width: 100px;
  height: 100px;
  z-index: 2;
  cursor: pointer;
}

.right-btn-img {
  position: absolute;
  right: 10%;
  width: 100px;
  height: 100px;
  z-index: 2;
  cursor: pointer;
}

/* Medium and Large Desktops */
@media (min-width: 1200px) {
}

/* Small Laptops and Desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Tablets */
@media (min-width: 768px) and (max-width: 991px) {
  .ImageCarousel {
    width: "40%";
    /* margin: auto; */
  }

  .img-container-carousel {
    position: relative;
    transition: transform 0.5s ease;
    /* width: 20vw; */
  }

  .carouse-bgc {
    width: 300px;
    /* width: 300px; */
    /* transition: transform 0.5s ease; */
  }

  .carousel-img {
    position: absolute;
    width: 70%;
    left: 10%;
    /* transition: transform 0.5s ease; */
}

  .left-btn-img {
    position: absolute;
    left: 1%;
    width: 20%;
    height: 20%;
    z-index: 2;
  }

  .right-btn-img {
    position: absolute;
    right: 8%;
    width: 20%;
    height: 20%;
}
}

@media (min-width: 479px) and (max-width: 767px) {
.ImageCarousel {
  width: 70%;
  margin: 0;
}

.image-carousel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

}

/* Mobile Phones */

@media (max-width: 479px) {
  .ImageCarousel {
    width: 100%;
    margin: 0;
  }
  
  .image-carousel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .left-btn-img {
    position: absolute;
    left: 0%;
    width: 80px;
    height: 80px;
    z-index: 2;
    cursor: pointer;
}

  .right-btn-img {
    position: absolute;
    right: 9%;
    width: 80px;
    height: 80px;
    z-index: 2;
    cursor: pointer;
}
}
