.popup-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  font-size: 1.8rem;
  align-items: center;
  background: transparent linear-gradient(90deg, #e55a7b 0%, #b23b57 100%) 0% 0%
    no-repeat padding-box;
  border: 1px solid #707070;
  padding: 0.8rem 1.5rem;
}

.popup-form-heading {
  font-size: 1.2rem;
}

.popup-form-content {
  background-color: #fff;
  /* padding: 20px; */
  border-radius: 5px;
  position: relative;
  width: 400px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.form > label {
  padding: 10px 5px 10px 5px;
}

.input {
  border: 2px solid #e55a7b;
  border-radius: 20px;
  padding: 5px 15px;
  height: 24px;
}

input::placeholder {
  color: #e55a7b;
}

.textarea {
  padding: 12px 15px 10px 12px;
  height: 42px;
}

.textarea::placeholder {
  color: #e55a7b;
}

.dropdown-form {
  height: 40px;
  color: #e55a7b;
}

.round-btn-popup {
  padding: 0.5rem 1rem;
  background: #b23b57 0% 0% no-repeat padding-box;
  border: 2px solid #b23b57;
  border-radius: 38px;
  text-align: center;
  color: #ffffff;
  margin: 1rem 0;
  width: 140px;
  cursor: pointer;
}

.note {
  padding: 10px 5px 5px 5px;
  font-size: 1rem;
  line-height: 24px;
  color: #b23b57;
  word-wrap: break-word;
}
