.min-info-container {
  width: 1250px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.mid-square-bg {
  width: 100%;
  position: absolute;
  top: -640px;
  left: -790px;
  z-index: -2;
}

.reverse-pink-line {
  /* width: 30vw; */
  align-self: flex-end;
  transform: scaleX(-1);
  margin-top: -4rem;
}

.first-mid-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  margin: 4rem 0;
}

.left-mid-info-container {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.left-mid-info-container > img:nth-child(1) {
  margin-bottom: 1.8rem;
}

.left-mid-info-container > img:nth-child(2) {
  width: 24vw;
}

.left-mid-info-container > p {
  font-size: 2rem;
  margin-top: 1.8rem;
}

.right-mid-info-container {
  width: 65%;
  margin-top: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* second conatiner  */
.mid-bottom-container {
  width: 1250px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 6rem;
  position: relative;
}

.left-mid-bottom-info-container {
  width: 50%;
  display: flex;
  justify-content: space-around;
}

.left-mid-bottom-info-container > img {
  width: 75%;
}

.right-mid-bottom-info-container {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.right-mid-bottom-info-container > img {
  width: 80%;
}

.right-mid-bottom-info-container > h2 {
  font-size: 2rem;
  margin: 1rem 0;
}

.right-mid-bottom-info-container > p {
  font-size: 1.2rem;
  margin: 0.8rem 0;
}

/* .right-mid-bottom-info-container > img {
  width: 24vw;
} */

.right-mid-bottom-info-container > ul > li {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.3rem;
}

.right-mid-bottom-info-container > ul > li > img {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.right-mid-bottom-info-container > ul > li > p {
  font-size: 0.75rem;
}

@media (min-width: 1440px) {
  .left-mid-info-container > img:nth-child(2) {
    width: 85%;
  }
}

@media (max-width: 1440px) {
  .min-info-container {
    width: auto;
  }
  .mid-bottom-container {
    width: auto;
  }
  .mid-square-bg {
    width: 100%;
    position: absolute;
    top: -610px;
    left: -930px;
    z-index: -2;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .min-info-container {
    width: auto;
  }

  .mid-bottom-container {
    width: auto;
  }
  .left-mid-bottom-info-container {
    width: 50%;
  }

  .left-mid-bottom-info-container > img {
    width: 75%;
  }

  .right-mid-bottom-info-container {
    width: 50%;
  }

  .right-mid-bottom-info-container > img {
    width: 40vw;
  }

  .right-mid-bottom-info-container > h2 {
    font-size: 2.4rem;
    margin: 1rem 0;
  }

  .right-mid-bottom-info-container > p {
    font-size: 1.4rem;
    margin: 0.8rem 0;
  }

  .right-mid-bottom-info-container > ul > li > img {
    width: 15px;
    height: 15px;
    margin-right: 0.8rem;
  }

  .right-mid-bottom-info-container > ul > li > p {
    font-size: 0.95rem;
  }

  .mid-square-bg {
    width: 100%;
    position: absolute;
    top: -500px;
    left: -750px;
    z-index: -2;
}
}

/* Tablets (768px - 1023px) */
@media (min-width: 767px) and (max-width: 1024px) {
  .min-info-container {
    width: auto;
  }

  .mid-bottom-container {
    width: auto;
  }

  .left-mid-bottom-info-container {
    width: 50%;
  }

  .left-mid-bottom-info-container > img {
    width: 75%;
  }

  .right-mid-bottom-info-container {
    width: 50%;
  }

  .right-mid-bottom-info-container > img {
    width: 40vw;
  }

  .right-mid-bottom-info-container > h2 {
    font-size: 2.4rem;
    margin: 1rem 0;
  }

  .right-mid-bottom-info-container > p {
    font-size: 1.4rem;
    margin: 0.8rem 0;
  }

  .right-mid-bottom-info-container > ul > li > img {
    width: 15px;
    height: 15px;
    margin-right: 0.8rem;
  }

  .right-mid-bottom-info-container > ul > li > p {
    font-size: 0.95rem;
  }
  .mid-square-bg {
    width: 100%;
    position: absolute;
    top: -400px;
    left: -575px;
    z-index: -2;
}
}

/* Mobile devices (480px - 767px) */
@media (min-width: 580px) and (max-width: 767px) {
  .min-info-container {
    width: auto;
  }

  .mid-bottom-container {
    width: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 15px;
    margin-top: 8rem;
  }

  .first-mid-info-container {
    display: block;
  }

  .left-mid-info-container {
    width: 100%;
  }

  .left-mid-info-container > img:nth-child(1) {
    width: 50%;
  }

  .left-mid-info-container > img:nth-child(2) {
    width: 100%;
  }
  .left-mid-info-container > p {
    font-size: 1.8rem;
    margin: 1.8rem 0 1rem 0;
  }

  .right-mid-info-container {
    width: 100%;
    margin-top: 2rem;
  }
  .left-mid-bottom-info-container {
    width: 100%;
  }

  .left-mid-bottom-info-container > img {
    width: 100%;
  }

  .right-mid-bottom-info-container {
    width: 100%;
    margin-top: 2rem;
  }

  .right-mid-bottom-info-container > img {
    width: 55vw;
  }

  .reverse-pink-line {
    width: 50%;
    margin-top: -1rem;
  }
  .mid-square-bg {
    width: 100%;
    position: absolute;
    top: -700px;
    left: -450px;
    z-index: -2;
}
}

/* Small mobile devices (up to 479px) */
@media (max-width: 580px) {
  .mid-bottom-container {
    width: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 4rem;
  }

  .left-mid-bottom-info-container {
    width: 100%;
  }

  .left-mid-bottom-info-container > img {
    width: 60%;
  }

  .right-mid-bottom-info-container {
    width: 100%;
    margin-top: 2rem;
  }

  .right-mid-bottom-info-container > img {
    width: 65vw;
  }

  .right-mid-bottom-info-container > ul > li > p {
    font-size: 0.9rem;
  }

  .right-mid-bottom-info-container > ul > li > img {
    width: 18px;
    height: 18px;
    margin-right: 0.5rem;
  }

  .min-info-container {
    width: auto;
    margin: 0 auto;
  }

  .reverse-pink-line {
    width: 40vw;
    margin-right: 14px;
    margin-top: 1px;
    margin-bottom: 5px;
  }

  .first-mid-info-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 4rem 0;
  }
  .left-mid-info-container {
    width: 100%;
    margin: auto;
  }
  .left-mid-info-container > img:nth-child(1) {
    width: 60%;
    /* display: none; */
  }

  .left-mid-info-container > img:nth-child(2) {
    width: 60%;
    align-self: center;
  }

  /* .left-mid-info-container > p {
    margin-bottom: 3rem;
  } */

  .first-mid-info-container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    align-items: center;
    margin: 1rem 0 2rem 0;
  }

  .right-mid-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
  }

  .right-mid-info-container {
    margin-top: 3rem;
  }

  .mid-square-bg {
    width: 100%;
    position: absolute;
    top: -1410px;
    left: -300px;
    z-index: -2;
}
}

@media (max-width: 320px) {
  .left-mid-info-container > img:nth-child(1) {
    width: 80%;
    /* display: none; */
  }
  .reverse-pink-line {
    width: 80%;
    margin-top: 0rem;
  }
}
