.home-top-main-section {
  padding: 10rem 0 2rem 0;
  flex-shrink: 1;
  overflow: hidden;
}

.home-top-inner-section {
  width: 1250px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.home-top-inner-section-right {
  position: relative;
}

.main-first-info {
  width: 1250px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10rem 0 5rem 0;
  flex-shrink: 1;
  overflow: hidden;
}

.home-main-img {
  width: 28vw;
  height: 100%;
  align-self: center;
  flex-shrink: 1;
}

.pink-head {
  font-size: 1rem;
  padding-left: 1rem;
  margin: 1.2rem 0;
  border-radius: 15px;
  background: transparent
    linear-gradient(90deg, #ed839c 0%, #f6c3cf31 52%, #ffffff00 100%) 0% 0%
    no-repeat padding-box;
}

.main-header {
  font-size: 2.6rem;
  word-spacing: 4px;
  margin: 1rem 0;
  flex-shrink: 1;
}

.hindi-line {
  font-size: 1.1rem;
  margin: 0.5rem 0;
}

.main-info-section {
  width: 34vw;
  flex-shrink: 1;
}

.btn-container {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.main-btn {
  border-color: #e55a7b;
  border: 2px solid #e55a7b;
  border-radius: 32px;
  padding: 4px 10px;
  font-size: 0.6rem;
  margin: 5px 5px;
  display: flex;
  align-items: center;
  text-align: start;
}

.main-btn > img {
  padding-right: 5px;
  width: 2rem;
}

.main-img-container {
  position: relative;
}

.bg-top {
  width: 130%;
  position: absolute;
  top: -440px;
  left: 8px;
  z-index: -1;
}

/* Large desktops or high-resolution displays (1440px and up) */
@media (min-width: 1440px) {
  .home-main-img {
    margin: 0 10rem 0 3rem;
    width: 70%;
}

.home-top-inner-section-left {
  width: 50%;
}

.home-top-inner-section-right {
  width: 50%;
  position: relative;
}

.main-info-section {
  width: 75%;
}
}

/* Desktops (1280px - 1439px) */
@media (min-width: 1280px) and (max-width: 1439px) {
  .home-top-inner-section {
    width: auto;
  }

  .home-main-img {
    margin: 0 10rem 0 3rem;
  }
  .main-info-section {
    width: 34vw;
  }
}

/* Laptops (1024px - 1279px) */
@media (min-width: 1023px) and (max-width: 1279px) {
  .home-top-inner-section {
    width: auto;
  }

  .home-top-inner-section-left {
    width: 50%;
}

  .home-main-img {
    margin: 0 10rem 0 3rem;
    /* height: 100%;
    align-self: center; */
  }
  .main-info-section {
    width: 100%;
  }

  .bg-top {
    width: 130%;
    position: absolute;
    top: -335px;
    left: 8px;
    z-index: -1;
}

}

/* Tablets (768px - 1023px) */
@media (min-width: 767px) and (max-width: 1023px) {
  .home-top-inner-section {
    width: auto;
  }

  .home-top-inner-section-left {
    width: 50%;
  }

  .home-top-inner-section-right {
    width: 50%;
  }

  .home-main-img {
    width: 75%;
    flex-shrink: 1;
  }
  .main-info-section {
    width: 100%;
    margin-top: 5rem;
    flex-shrink: 1;
  }

  .bg-top {
    width: 130%;
    position: absolute;
    top: -225px;
    left: 8px;
    z-index: -1;
}
}

/* Mobile devices (480px - 767px) */
@media (min-width: 480px) and (max-width: 767px) {

  .home-top-main-section {
    padding: 8rem 0 2rem 0;
    flex-shrink: 1;
    overflow: hidden;
  }

  .home-top-inner-section {
    width: auto;
    display: flex;
    flex-direction: column-reverse;
}

  .home-top-inner-section-left {
    width: 100%;
    margin-top: 1rem;
  }

  .home-top-inner-section-right {
    position: relative;
    width: 100%;
  }
  .home-main-img {
    width: 80%;
    margin-top: 0.8rem;
    flex-shrink: 1;
  }
  .main-info-section {
    width: 90vw;
    flex-shrink: 1;
  }
  .bg-top {
    width: 130%;
    position: absolute;
    top: -250px;
    left: 250px;
    z-index: -1;
}
}

/* Small mobile devices (up to 479px) */
@media (max-width: 480px) {

  .home-top-main-section {
    padding: 8rem 0 2rem 0;
    flex-shrink: 1;
    overflow: hidden;
  }

  .home-top-inner-section {
    width: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .home-main-img {
    width: 80%;
    margin-top: 0.8rem;
  }

  .main-img-container {
    order: 1;
  }

  .main-info-section {
    width: 90vw;
    margin-top: 3rem;
    order: 2;
  }

  .bg-top {
    width: 130%;
    position: absolute;
    top: -350px;
    left: 200px;
    z-index: -1;
}
}
